<template>
  <div>
    <div class="col-12">
      <div class="tw-p-6 sm:tw-p-10">
        <div
          class="tw-block sm:tw-flex tw-mb-6 tw-items-center tw-justify-between"
        >
          <h5
            class="tw-text-2xl inter-semibold tw-text-black tw-mb-3 sm:tw-mb-0"
          >
            Jadwal barber
          </h5>
          <div>
            <!-- <Button
              label="Impor"
              icon="pi pi-cloud-download"
              iconPos="left"
              class="tw-h-9 tw-text-sm tw-mr-3 tw-mb-3 sm:tw-mb-0 tw-bg-transparent tw-text-black tw-hover:tw-bg-neutral-300 tw-border-gray-300"
            />
            <Button
              label="Ekspor"
              icon="pi pi-cloud-upload"
              iconPos="left"
              class="tw-h-9 tw-text-sm tw-mr-3 tw-mb-3 sm:tw-mb-0 tw-bg-transparent tw-text-black tw-hover:tw-bg-neutral-300 tw-border-gray-300"
            /> -->
            <Button
              label="Tambah"
              icon="pi pi-plus"
              class="tw-h-9 tw-text-sm tw-mb-3 sm:tw-mb-0 tw-bg-transparent tw-text-white tw-hover:tw-bg-neutral-300 tw-border-blue-light tw-bg-blue-light"
              @click="navigateToCreate"
            />
          </div>
        </div>
        <form @submit.prevent="doSearch()" class="tw-mb-8 tw-flex">
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText
              v-model="keyword"
              placeholder="Cari disini"
              class="tw-h-11 md:tw-w-80 tw-w-auto mr-3"
            />
          </span>
          <Button
            label="Filter"
            type="submit"
            icon="pi pi-align-center"
            iconPos="left"
            class="tw-h-11 tw-text-sm tw-bg-transparent tw-text-black tw-hover:tw-bg-neutral-300 tw-border-gray-300 tw-block"
          />
          <Button
            label="Rollback"
            icon="pi pi-undo"
            iconPos="left"
            class="tw-h-11 tw-text-sm tw-bg-transparent tw-text-black tw-hover:tw-bg-neutral-300 tw-border-gray-300 tw-block"
            @click="rollbackSearch"
          />
        </form>
      </div>
    </div>
    <!-- Begin vue cal -->
    <div class="col-12">
      <vue-cal
        class="vuecal--green-theme"
        ref="vuecal"
        locale="id"
        :time-cell-height="100"
        :time-cell-width="100"
        hide-week-numbers
        style="margin-top: 2rem"
        :selected-date="currentDate"
        :time-from="0 * 60"
        :time-to="24 * 60"
        :disable-views="['years', 'year', 'day', 'month']"
        :events="events"
        @view-change="handleViewChange"
      >
        <template #event="{ event }">
          <div :class="['vuecal__event-content', getEventClass(event)]">
            <h6 class="text-left">
              {{
                new Intl.DateTimeFormat("default", {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: false,
                }).format(new Date(event.start))
              }}
              -
              {{
                new Intl.DateTimeFormat("default", {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: false,
                }).format(
                  new Date(event.end).setHours(
                    new Date(event.end).getHours() - 1
                  )
                )
              }}
            </h6>
            <div class="flex flex-wrap">
              <div
                v-for="(employee, index) in getFirstThreeEmployees(event)"
                :key="index"
                class="ml-3 relative"
                @click="showImage(employee.image, employee.name, event)"
              >
                <div class="mt-3">
                  <img
                    alt="Employee"
                    v-if="employee.image"
                    :src="employee.image"
                    class="border rounded-full"
                    style="width: 40px; height: 40px"
                    :style="{
                      'border-color': isEmployeeMatched(employee) ? 'red' : '',
                    }"
                    v-tooltip="'Employee: ' + employee.name"
                  />
                  <div
                    v-else
                    class="w-10 h-8 flex items-center justify-center text-white"
                    :style="{
                      'background-color': isEmployeeMatched(employee)
                        ? 'red'
                        : '',
                    }"
                    v-tooltip="'Employee: ' + employee.name"
                  >
                    <div
                      class="icon-placeholder bg-gray-500"
                      style="width: 40px; height: 40px; border-radius: 10px"
                    >
                      {{ employee.name.charAt(0).toUpperCase() }}
                    </div>
                    <div
                      v-if="index === 0"
                      class="tooltip absolute bg-black text-white p-1 -mt-8 ml-2 rounded"
                    >
                      {{ employee.name }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="event.employees.length > 3"
                class="mt-3 bg-blue-500 px-2 py-1 text-white cursor-pointer"
                @click="showMoreEmployees(event)"
              >
                <i class="fa fa-plus"></i> +{{ event.employees.length - 3 }}
              </div>
            </div>
          </div>
          <!-- Modal untuk popup image -->
          <div v-if="selectedImage" class="modal" @click="selectedImage = null">
            <div class="modal-content">
              <span class="close" @click="selectedImage = null">&times;</span>
              <h2 class="modal-title">Nama Barber : {{ selectedBarber }}</h2>
              <h2 class="modal-date">Jam Operasional : {{ selectedDate }}</h2>
              <h2 class="modal-date">Jam Selesai : {{ selectedEnd }}</h2>
              <img
                class="modal-image"
                :src="selectedImage"
                alt="Gambar Barber"
              />
            </div>
          </div>
          <div
            v-if="isRemainingEmployeesPopupVisible"
            class="modal"
            @click="hideRemainingEmployeesPopup"
          >
            <div class="modal-content">
              <span class="close" @click="hideRemainingEmployeesPopup"
                >&times;</span
              >
              <h2 class="modal-title">
                {{ remainingEmployees.length }} Karyawan lain yang bekerja pada
                jam ini
              </h2>
              <ul>
                <li
                  v-for="(employee, index) in remainingEmployees"
                  :key="index"
                >
                  {{ employee.name }}
                </li>
              </ul>
            </div>
          </div>
        </template>
      </vue-cal>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import BarberService from "@/service/BarberService";
import dayjs from "dayjs";
import { useAuthRole } from "@/composables/auth";
import { ref } from "vue";

export default {
  components: { VueCal },
  setup() {
    const currentUser = ref(null);
    const { getUser } = useAuthRole();
    currentUser.value = getUser();

    return { currentUser };
  },
  data() {
    return {
      schedule: [],
      selectedEvent: {},
      showDialog: true,
      events: [],
      barbersService: null,
      currentDate: new Date().toISOString().split("T")[0],
      colors: ["red", "green", "blue", "yellow", "purple"],
      selectedImage: null,
      selectedBarber: null,
      keyword: "",
      barbers: [],
      searchPerformed: false,
      showTable: false,
      currentEvent: null,
      minSplitWidth: 0,
      minCellWidth: 200,
      initialViewDate: new Date(),
      isNoMatchDialogVisible: false,
      currentIndex: 0,
      isRemainingEmployeesPopupVisible: false,
      remainingEmployees: [],
    };
  },

  created() {
    this.barbersService = new BarberService();
    this.initialViewDate = new Date();
  },

  async mounted() {
    const today = dayjs().format("YYYY-MM-DD");
    await this.fetchBarberSchedule(today, today);
    this.barbers = [...this.events];
  },

  methods: {
    async fetchBarberSchedule(startDate, endDate) {
      if (this.currentUser) {
        if (
          this.currentUser.roles.includes("admin_salon") ||
          this.currentUser.roles.includes("admin_priti")
        ) {
          console.info("User is allowed. Proceeding with fetch.");
        } else {
          console.error("User does not have the required role.");
        }
      } else {
        console.error("currentUser is not defined.");
      }
      try {
        if (
          !this.currentUser ||
          !(
            this.currentUser.roles.includes("admin_salon") ||
            this.currentUser.roles.includes("admin_priti")
          )
        ) {
          throw new Error("Invalid user or user roles.");
        }
        const url = `${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/barbers/availability/ddl`;
        const params = { start_date: startDate, end_date: endDate };

        if (this.currentUser.roles.includes("admin_salon")) {
          params.salon_id = this.currentUser.salon_id;
        }

        const availabilityResponse = await axios.get(url, { params });

        if (availabilityResponse.data?.data?.barber_availabilities) {
          this.schedule = await this.fetchBarberDetails(
            availabilityResponse.data.data.barber_availabilities
          );
          this.events = this.scheduleToEvents(this.schedule);
        } else {
          console.error(
            "Unexpected data structure for schedule:",
            availabilityResponse
          );
        }
      } catch (error) {
        console.error("Error fetching barber schedule:", error);
      }
    },
    async handleViewChange({ startDate, endDate }) {
      const start = dayjs(startDate).format("YYYY-MM-DD");
      const end = dayjs(endDate).format("YYYY-MM-DD");

      await this.fetchBarberSchedule(start, end);
    },

    async fetchBarberDetails(barberAvailabilities) {
      return Promise.all(
        barberAvailabilities.map(async (item, idx) => {
          const barberResponse = await axios.get(
            `${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/barbers/${item.barber_id}`
          );
          const barber = barberResponse.data.data;
          return {
            ...item,
            no: idx + 1,
            barber_name: barber.name,
            barber_image: barber.image,
          };
        })
      );
    },

    scheduleToEvents(schedule) {
      return schedule.map((item) => {
        // Assign a random color class from the colors array
        const randomColorClass =
          this.colors[Math.floor(Math.random() * this.colors.length)];
        return {
          start: item.start || "",
          end: item.end || "",
          title: item.barber_name || "Untitled",
          class: randomColorClass,
          employees: [
            {
              name: item.barber_name,
              image: item.barber_image,
            },
          ],
        };
      });
    },
    onCalendarClick(event) {
      this.selectedEvent = event;
      this.showDialog = true;
    },

    getTitle(rowData) {
      return rowData.title[0];
    },

    getEventClass(event) {
      return event.class;
    },

    formatTime(date) {
      let hours = date.getHours();
      let minutes = date.getMinutes();
      minutes = minutes < 10 ? "0" + minutes : minutes;
      return hours + ":" + minutes;
    },

    showImage(image, name, event) {
      this.selectedImage = image || "path/to/default/image.jpg";
      this.selectedBarber = name;
      this.selectedDate = this.formatDate(event.start);

      let endDate = new Date(event.end);
      endDate.setHours(endDate.getHours() - 1);
      this.selectedEnd = this.formatDate(endDate);
    },

    formatDate(date) {
      let months = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      let selectedDate = new Date(date);
      return `${selectedDate.getDate()} ${
        months[selectedDate.getMonth()]
      } ${selectedDate.getFullYear()} ${this.formatTime(selectedDate)}`;
    },

    navigateToCreate() {
      this.$router.push({ name: "create-barber" });
    },

    doSearch() {
      let keyword = this.keyword.toLowerCase();
      let matchingEvents = this.events.filter((event) =>
        event.employees.some((item) =>
          item.name.toLowerCase().includes(keyword)
        )
      );

      if (matchingEvents.length > 0 && this.$refs.vuecal) {
        if (this.currentIndex >= matchingEvents.length) {
          this.currentIndex = 0;
        }

        let matchingEvent = matchingEvents[this.currentIndex];
        let matchingDate = new Date(matchingEvent.start);

        this.$nextTick(() => {
          this.$refs.vuecal.switchView("day", matchingDate);
        });

        this.currentIndex++;
      } else {
        this.showNoMatchDialog();
      }

      this.searchPerformed = true;
    },

    rollbackSearch() {
      this.searchPerformed = false;
      this.keyword = "";
      this.showTable = false;

      if (this.initialViewDate && this.$refs.vuecal) {
        this.$nextTick(() => {
          this.$refs.vuecal.switchView("day", this.initialViewDate);
        });
      }
    },

    getFirstThreeEmployees(event) {
      return event.employees.slice(0, Math.min(3, event.employees.length));
    },

    getRemainingEmployees(event) {
      const allEmployees = event.employees;
      const displayedEmployees = this.getFirstThreeEmployees(event);
      const remainingEmployees = allEmployees.filter(
        (employee) => !displayedEmployees.includes(employee)
      );
      return remainingEmployees;
    },

    showMoreEmployees(event) {
      this.currentEvent = event;
      this.remainingEmployees = this.getRemainingEmployees(event);
      this.isRemainingEmployeesPopupVisible = true;
    },

    showNoMatchDialog() {
      this.isNoMatchDialogVisible = true;
    },

    hideNoMatchDialog() {
      this.isNoMatchDialogVisible = false;
    },

    isEmployeeMatched(employee) {
      if (employee && employee.name) {
        return this.keyword.toLowerCase().includes(employee.name.toLowerCase());
      }
      return false;
    },

    hideRemainingEmployeesPopup() {
      this.isRemainingEmployeesPopupVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.vuecal__event {
  cursor: pointer;
}

.vuecal__event-content {
  font-style: italic;
  height: 100%;
  width: auto !important;
  border-radius: 10px;
}

.vuecal__event.red,
.vuecal__event.green,
.vuecal__event.blue,
.vuecal__event.yellow,
.vuecal__event.purple,
.red,
.green,
.blue,
.yellow,
.purple {
  padding: 0 !important;
  border: none !important;
}

.red {
  background-color: #ffe6e6;
}
.green {
  background-color: #ebf6e8;
}
.blue {
  background-color: #e6f1f8;
}
.yellow {
  background-color: #fef9e6;
}
.purple {
  background-color: #efd8f9;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.146);
}

.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 700px;
  max-height: 80%;
  overflow: auto;
}

.modal-image {
  width: 100%;
  max-height: 400px;
  object-fit: contain;
}

.close {
  position: absolute;
  top: 10px;
  right: 15px;
  color: #000000;
  font-size: 28px;
  font-weight: bold;

  &:hover,
  &:focus {
    color: #ff0000;
    cursor: pointer;
  }
}

.modal-title {
  text-align: center;
  margin-bottom: 15px;
}
</style>
